import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

export default responsiveFontSizes(
  createMuiTheme({
    palette: {
      type: 'light',
      common: {
        black: '#262626',
        white: '#ffffff',
      },
    },
    typography: {
      fontFamily: ['Rambla', 'sans-serif', 'Roboto', 'Helvetica', 'Arial'].join(
        ','
      ),
      letterSpacing: 10,
    },
  })
);
