import React from 'react';
import { CssBaseline, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Navbar from '../components/NavBar';

const useStyles = makeStyles((theme) => {
  const { toolbar } = theme.mixins;
  return {
    toolbar,
    main: {
      top: toolbar,
    },
  };
});

export default ({ children }) => {
  const classes = useStyles();
  return (
    <Box padding={1}>
      <CssBaseline />
      <Navbar />
      <Toolbar className={classes.toolbar} />
      <main className={classes.main}>{children}</main>
    </Box>
  );
};
