import React from 'react';
import AppLayout from '../../layouts/AppLayout';
import RecentArticlesContainer from '../containers/RecentArticlesContainer';

export default () => {
  return (
    <AppLayout>
      <RecentArticlesContainer />
    </AppLayout>
  );
};
