import axios from 'axios';
import env from '../config/environment';

const httpInstance = axios.create({
  baseURL: `${env.BACKEND_URL}`,
  headers: {
    Accepted: 'application/json',
    'Content-Type': 'application/json',
  },
});

export default httpInstance;
