import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ArticleCard from './ArticleCard';
import IsLoading from '../../components/IsLoading';
import NoContent from '../../components/NoContent';

export default ({ articles, loading, error }) => {
  return loading ? (
    <IsLoading text='Loading posts' />
  ) : error ? (
    <NoContent
      title={error}
      path={`https://inyarwanda.com?source=${location.href}`}
      pathName='Go To Inyarwanda'
    />
  ) : (
    <React.Fragment>
      <Grid container justify='center' spacing={1}>
        {articles.map((article, index) => (
          <Grid item sm={4} xs={4} md={3} key={index}>
            <ArticleCard {...article} />
          </Grid>
        ))}
      </Grid>
      {!loading && !error && (
        <Box marginY={4}>
          <Grid container justify='center'>
            <a href={`https://inyarwanda.com?source=${location.href}`}>
              <Button color='primary' variant='contained'>
                More posts
              </Button>
            </a>
          </Grid>
        </Box>
      )}
    </React.Fragment>
  );
};
