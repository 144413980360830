import React, { useEffect, useState } from 'react';
import apiService from '../../services/apiService';
import ArticlesListComponent from '../components/ArticlesListComponent';

export default () => {
  const [state, setState] = useState({
    posts: [],
    loading: true,
  });
  useEffect(() => {
    apiService({ path: '/inyarwanda', method: 'GET' }).then((res) => {
      if (res.error) {
        setState({
          loading: false,
          error: 'Failed loading posts, please try again',
        });
      } else {
        setState({
          posts: res.results,
          loading: false,
        });
      }
    });
  }, []);
  return (
    <ArticlesListComponent
      articles={state.posts}
      loading={state.loading}
      error={state.error}
    />
  );
};
