import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    // width: 300,
    // '@media(max-width:300px)': {
    //   width: 240,
    // },
  },
  media: {
    height: 200,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      height: 100,
    },
  },
  cardContent: {
    padding: '0.4em',
  },
  content: {
    height: 100,
    overflow: 'hidden',
    wordBreak: 'break-all',
    lineHeight: '18px',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('sm')]: {
      height: 50,
      lineHeight: '12px',
      fontSize: '0.8em',
    },
  },
  subtitle: {
    fontSize: '0.8em',
  },
}));

export default ({
  title,
  subtitle,
  link,
  categoryName,
  articleUrl,
  wallPhoto,
}) => {
  const classes = useStyles();
  return (
    <a
      href={`${articleUrl}?source=${location.href}`}
      style={{ textDecoration: 'none' }}
    >
      <Card className={classes.root} variant='outlined'>
        <CardActionArea>
          <CardMedia
            className={classes.media}
            image={wallPhoto}
            title={title}
          />
          <CardContent className={classes.cardContent}>
            <Typography gutterBottom variant='h6' className={classes.content}>
              {title}
            </Typography>
            <Typography
              variant='body2'
              color='textSecondary'
              component='small'
              className={classes.subtitle}
            >
              {subtitle || categoryName}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </a>
  );
};
