import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import logo from '../assets/images/logo.png';

const useStyles = makeStyles((theme) => {
  return {
    logoFlex: {
      [theme.breakpoints.down('xs')]: {
        margin: '0 auto',
      },
    },
    logo: {
      width: 40,
      height: 40,
    },
    toolbar: theme.mixins.toolbar,
    appBar: {
      border: 'none',
      boxShadow: 'none',
      background: theme.palette.common.black,
    },
    navLink: {
      textDecoration: 'none',
      fontSize: '1.2em',
      color: theme.palette.common.white,
      textTransform: 'none',
    },
  };
});

export default () => {
  const classes = useStyles();
  return (
    <AppBar position='fixed' className={classes.appBar} color='primary'>
      <Toolbar className={classes.toolbar}>
        <a
          href={`https://inyarwanda.com?source=${location.href}`}
          className={classes.logoFlex}
        >
          <img src={logo} alt='Inyarwanda' className={classes.logo} />
        </a>
        <Grid container justify='flex-end'>
          <Button variant='outlined'>
            <a
              href={`https://inyarwanda.com?source=${location.href}`}
              className={classes.navLink}
            >
              Visit site
            </a>
          </Button>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};
