import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '10vh 0',
    textAlign: 'center',
    width: '100%',
  },
}));

export default (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress />
      <Typography align='center'>{props.text}</Typography>
    </div>
  );
};
